import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import { useLocation } from "react-router-dom"; // Ensure you're using react-router

const Navbar = () => {
  const [logo, setLogo] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation(); // Get the current path
  // const [conversionMenuItem, setConversionMenuItem] = useState(null);

  useEffect(() => {
    const client = createClient({
      space: "4wxah41ka2oo",
      accessToken: "KnIMExE3pTn5fSPRd0RpUryrfaijOdgtTiM_H7Qbl7Q",
    });

    const getNavbarContent = async () => {
      try {
        const response = await client.getEntries({
          "sys.id": "4OXAvIwuujjIDESA1tYLcZ",
        });
        const navbarEntry = response.items[0];
        setLogo(navbarEntry.fields.logo.fields.file.url);
        setMenuItems(navbarEntry.fields.menuItems);
        // setConversionMenuItem(navbarEntry.fields.conversionMenuItem);
      } catch (error) {
        console.error("Error fetching navbar content:", error);
      }
    };

    getNavbarContent();
  }, []);

  return (
    <nav>
      <div className="logo">
        <a href="/"> {logo && <img src={logo} alt="Logo" />} </a>
      </div>
      <ul className="menu">
        {menuItems.map((item, index) => (
          <li key={index}>
            <a
              href={`/${item.toLowerCase()}`}
              className={
                location.pathname === `/${item.toLowerCase()}` ? "active" : ""
              }
            >
              {item}
            </a>
          </li>
        ))}

        {/* <div className="conversionButton"> {conversionMenuItem && <li><a href={`/${conversionMenuItem.toLowerCase()}`}>{conversionMenuItem}</a></li>}</div> */}
      </ul>
    </nav>
  );
};

export default Navbar;
