import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation
// import "./index.css"; // Make sure your CSS file is properly linked
import "./home.css"; // Make sure your CSS file is properly linked
import { createClient } from "contentful";
// import { List } from "lucide-react";

function BlogPreview() {
  const [blogPosts, setBlogPosts] = useState([]);

  const client = createClient({
    space: "4wxah41ka2oo",
    accessToken: "KnIMExE3pTn5fSPRd0RpUryrfaijOdgtTiM_H7Qbl7Q",
  });

  useEffect(() => {
    async function fetchBlogPosts() {
      try {
        const entries = await client.getEntries({
          content_type: "blogHomepage",
          order: "-fields.publishDate", // Order by most recent
          limit: 3, // Fetch only the 3 most recent posts
        });
        setBlogPosts(entries.items);
      } catch (error) {
        console.log("Error fetching blog posts:", error);
      }
    }

    fetchBlogPosts();
  }, [client]);

  // const Home = () => {
  return (
    <div className="home-page">
      <header>
        <div className="hero-section">
          <h1>
            <span>Become an expert in</span>
            <br /> Real Estate <strong>Financial Modeling</strong>
          </h1>
          <p>
            Learn cash flow modeling from scratch and improve or kick-start your
            career!
          </p>
          <div className="actionButton-container">
            <Link to="/blog" className="actionButton">
              <span>Get started</span>
            </Link>
            {/* <Link
              to="https://www.youtube.com/@bluelane_ventures"
              className="actionButton"
            >
              <span>Watch on YouTube</span>
            </Link>
            <Link to="/youtube" className="actionButton">
              <span>Check out my Course</span>
            </Link> */}
          </div>
        </div>
      </header>

      {/* <section className="gap-section"></section> */}

      <section className="template">
        <div className="template-container">
          <h2 className="template-title">Here's where it all starts</h2>
          <div className="template-info-box">
            <p className="template-description">
              Whether you're just starting out in commercial real estate or are
              already an experienced professional, this course helps you create
              a dynamic Excel cash flow template for any scenario. Learn how to
              set up an efficient sheet with clear inputs, an extensive
              calculation sheet, and visually appealing outputs.
              <br />
              <br />
              <strong>
                Please note: This course is specifically designed for office,
                retail, and logistics properties. <br />
                The template is designed to suit the German market, but can be
                adjusted to all countries!
              </strong>
            </p>
          </div>
        </div>

        <div className="template-features">
          <div className="features-right">
            <h2 className="template-title">Key Features</h2>
            <div className="features-grid">
              <div className="feature-box-traditional">
                <h3 className="template-subtitle">
                  Traditional cash flow models
                </h3>
                <ul className="traditional-models">
                  <li>Limited rent roll input</li>
                  <li>Basic projections on annual basis</li>
                  <li>Assumptions are generalized and not explained</li>
                  <li>Few financing options; limited flexibility</li>
                  <li>No shortcuts or optimizations for speed</li>
                </ul>
              </div>
              <div className="feature-box-new">
                <h3 className="template-subtitle">Blackline Ventures' Model</h3>
                <ul className="new-models">
                  <li>Extensive rent roll for various lease scenarios</li>
                  <li>Monthly projections and annual overview</li>
                  <li>Customized assumptions with in-depth explanation</li>
                  <li>
                    Advanced financing options (senior & junior loans, etc.)
                  </li>
                  <li>Time-saving shortcuts, formatting, and visual tips</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Why Choose Our Template */}
        <div className="template-highlight">
          <h2 className="template-title">Why choose the blackline course?</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <h3>Market Expertise</h3>
              <p>
                Valuable cash flow creation learings from a real estate
                professional.
              </p>
            </div>
            <div className="feature-item">
              <h3>Create your own template</h3>
              <p>Expert level cash flow creation for all kinds of users.</p>
            </div>
            <div className="feature-item">
              <h3>User-Friendly set-up</h3>
              <p>Simplifies inputs and outputs for a better user experience.</p>
            </div>
            <div className="feature-item">
              <h3>Formatting and design</h3>
              <p>
                Template designs matter. Learn color coding, borders and
                layouts.
              </p>
            </div>
            <div className="feature-item">
              <h3>Efficient Spreadsheet Setup</h3>
              <p>
                Create organized, intuitive spreadsheets with minimal file size.
              </p>
            </div>
            <div className="feature-item">
              <h3>Master shortcuts</h3>
              <p>
                Learn the most imporant shortcuts to use excel without a
                keyboard.
              </p>
            </div>
            <div className="feature-item">
              <h3>Highest flexibility</h3>
              <p>From financing to capex, re-letting scenarios and more.</p>
            </div>
            <div className="feature-item">
              <h3>Understand the output</h3>
              <p>Conduct sensitivity analysis and understand KPIs.</p>
            </div>
          </div>
        </div>

        {/*  
        <div className="template-features">
          <h2 className="template-title">
            Who is the course and cash flow template designed for?
          </h2>
          <ul className="audience-list">
            <li>Real Estate Investors navigating the German market.</li>
            <li>Financial Analysts refining modeling skills.</li>
            <li>Interns eager to master financial modeling.</li>
            <li>Developers streamlining project execution.</li>
          </ul>
        </div> */}

        {/* Call to Action */}
        <div className="actionButton-container">
          <Link to="/enroll" className="actionButton">
            Start Your Journey
          </Link>
        </div>
      </section>

      <section className="overview-section-main">
        <div className="overview-section">
          <h2>Learn Financial Modeling in Excel with ...</h2>
          <div className="featured-items">
            <div className="featured-item">
              <h3>My Blog</h3>
              <p>
                In my blog, you can read through the most important formulas,
                cash flow, tips and tricks - line by line. A good way to find
                answers to the most common issues.
              </p>
              <div className="actionButton-container">
                <Link to="/blog" className="actionButton">
                  <span>Read my Blog</span>
                </Link>
              </div>
            </div>
            <div className="featured-item">
              <h3>My Youtube-Series</h3>
              <p>
                On my channel, you will find hands-on tutorials for financial
                modeling. From short tips to long tutorials, you will always
                find the right video for you.
              </p>
              <div className="actionButton-container">
                <Link
                  to="https://www.youtube.com/@bluelane_ventures"
                  className="actionButton"
                >
                  <span>YouTube-Channel</span>
                </Link>
              </div>
            </div>
            <div className="featured-item">
              <h3>My Course</h3>
              <p>
                Start here to learn cash flow modeling in Excel from scratch!
                This series will teach you everything to create a top grade cash
                flow from a blank workbook.
              </p>
              <div className="actionButton-container">
                <Link to="/blog" className="actionButton">
                  <span>My Course</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-preview-main">
        <div className="blog-preview">
          <h2>Latest from the Blog</h2>
          <div className="blog-list-preview">
            {blogPosts.map((post) => (
              <div className="blog-post-preview" key={post.sys.id}>
                {/* <img
                  src={
                    post.fields?.BlogMedia?.fields?.file?.url ||
                    "/placeholder.jpg"
                  }
                  alt={post.fields?.BlogMedia?.fields?.title || "Blog Post"}
                  className="blog-post-image"
                /> */}
                <div className="blog-post-content">
                  <Link to={`/blog/${post.sys.id}`}>
                    {" "}
                    <h3>{post.fields?.BlogTitle}</h3>
                  </Link>
                  <p>{post.fields?.shortBlogSummary}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="what-you-will-learn-section">
        <div className="what-you-will-learn">
          <h2>You will learn...</h2>
          <list className="learnings">
            <div className="single-learning">
              <p className="checkbox">✓</p>
              <h3>How to set up an efficient spreadsheet</h3>
            </div>
            <div className="single-learning">
              <p className="checkbox">✓</p>
              <h3>
                How to create a good financial model for real estate investments
              </h3>
            </div>

            <div className="single-learning">
              <p className="checkbox">✓</p>
              <h3>How to use the most important shortcuts</h3>
            </div>
            <div className="single-learning">
              <p className="checkbox">✓</p>
              <h3>How to write concise and memory saving formulas</h3>
            </div>
            <div className="single-learning">
              <p className="checkbox">✓</p>
              <h3>How to color code your spreadsheet</h3>
            </div>
            <div className="single-learning">
              <p className="checkbox">✓</p>
              <h3>And much more...</h3>
            </div>
          </list>
          <div className="actionButton-container">
            <Link to="/blog" className="actionButton">
              <span>Get started</span>
            </Link>
          </div>
        </div>
      </section>

      {/* <section className="youtube-preview">
        <h2>Watch on YouTube</h2>
        <div className="youtube-video">
          <iframe
            src="https://www.youtube.com/embed/your-video-id"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section> */}
    </div>
  );
}

export default BlogPreview;
